<template>
  <CRow>
    <CCol col="12">
      <CCard v-if="itemData">
        <CCardHeader> السؤالة رقم: {{ $route.params.id }} </CCardHeader>
        <CCardBody>

          <CAlert color="info">
            <CRow>
              <CCol col="2">التصنيف</CCol>
              <CCol col="10"> {{ itemData.category }} </CCol>
            </CRow>
          </CAlert>

          <CAlert color="info">
            <CRow>
              <CCol col="2">العميل</CCol>
              <CCol col="10"> {{ itemData.client }} </CCol>
            </CRow>
          </CAlert>

          <CAlert color="info">
            <CRow>
              <CCol col="2">العنوان</CCol>
              <CCol col="10"> {{ itemData.title }} </CCol>
            </CRow>
          </CAlert>

          <CAlert color="info">
            <CRow>
              <CCol col="2">الوصف </CCol>
              <CCol col="10">
                <div v-html="itemData.description"></div>
              </CCol>
            </CRow>
          </CAlert>

          <CAlert color="info">
            <CRow>
              <CCol col="2"> الحالة </CCol>
              <CCol col="10">
                <b :class="`text-${getBadge(itemData.status)}`">
                  {{ itemData.status }}
                  <CButton v-if="(itemData.status == 0)" class="mx-2" color="success" @click="updateStatus(1, itemData.id)">
                    تفعيل
                  </CButton>
                  <CButton v-else color="danger" class="mx-2" @click="updateStatus(0, itemData.id)">
                    ايقاف
                  </CButton>
                </b>
              </CCol>
            </CRow>
          </CAlert>
          
          
          <CAlert color="info">
            <CRow>
              <CCol col="12">
                <Answers :answers="itemData.answers" />
              </CCol>
            </CRow>
          </CAlert>

        </CCardBody>
        <CCardFooter>
          <CButton color="info" @click="goBack">رجوع</CButton>

        </CCardFooter>
      </CCard>
      <Spinner v-else />
    </CCol>
  </CRow>
</template>

<script>
import Answers from "./Answers";
export default {
  name: "Question",
  components: {
    Answers,
  },
  data() {
    return {
      itemData: null,
      usersOpened: null,
    };
  },
  created() {
    this.getUser();
  },
  methods: {
    getUser() {
      this.toggleLoadSpinner();

      this.$http
        .get("questions/" + this.$route.params.id)
        .then((res) => {
          // handle success
          this.itemData = res.data.data;
          this.toggleLoadSpinner();
        })
        .catch((error) => {
          //
          this.toggleLoadSpinner();
        });
    },
    toggleLoadSpinner() {
      this.$store.commit("toggleLoadSpinner");
    },
    goBack() {
      this.$router.push({ path: "/questions" });
    },
    getBadge(status) {
      switch (status) {
        case 1:
          return "success";
        case 0:
          return "danger";
        default:
          return "primary";
      }
    },

    updateStatus(status, id) {
      this.toggleLoadSpinner();
      this.$http
        .put(`/questions/status/${id}`, { status })
        .then((res) => {
          this.itemData = res.data.data;
          this.toggleLoadSpinner();
        })
        .catch((error) => {
          // handle error
          this.toggleLoadSpinner();
        });
    }
  },
};
</script>
