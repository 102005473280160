<template>
    <div class="table-responsive">
        <table class="table table-bordered table-hover mb-5">
            <thead>
                <tr class="table-primary">
                    <th scope="col">المحامي</th>
                    <th scope="col">الاجابة</th>
                    <th scope="col">الحالة</th>
                    <th scope="col">الاجراءات</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="answer in answers" :key="answer.id">
                    <td>{{ answer.lawyer }}</td>
                    <td>
                        <div v-html="answer.description"></div>
                    </td>
                    <td>
                        <b :class="`text-${getBadge(answer.status)}`">
                            {{ answer.status == "1" ? "نشط" : "متوقف" }}
                        </b>                        
                    </td>
                    <td>
                        <CButton v-if="(answer.status == 0)" class="btn-sm" color="success" @click="updateStatus(1, answer.id)">
                            تفعيل
                        </CButton>
                        <CButton v-else color="danger" class="btn-sm" @click="updateStatus(0, answer.id)">
                            ايقاف
                        </CButton>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name: "Question",
    props: ["answers"],
    methods: {
        getBadge(status) {
            switch (status) {
                case 1:
                    return "success";
                case 0:
                    return "danger";
                default:
                    return "primary";
            }
        },
        toggleLoadSpinner() {
            this.$store.commit("toggleLoadSpinner");
        },

        updateStatus(status, id) {
            this.toggleLoadSpinner();
            this.$http
                .put(`/questions/answer/${id}`, { status })
                .then((res) => {
                    window.location.reload();
                    this.toggleLoadSpinner();
                })
                .catch((error) => {
                    // handle error
                    this.toggleLoadSpinner();
                });
        }
    },
};
</script>
